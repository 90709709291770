import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Back Squat 4×4\\@85% 1RM`}</p>
    <p>{`DB Stiff Leg Deadlift’s 4×4`}</p>
    <p>{`then,`}</p>
    <p><em parentName="p">{`Option 1:`}</em></p>
    <p><strong parentName="p">{`Granite Games Open WOD 1`}</strong></p>
    <p>{`Complete in 4:00:`}</p>
    <p>{`15 Squat Cleans (135/95)`}</p>
    <p>{`15 T2B`}</p>
    <p>{`12 Bar Facing Burpees`}</p>
    <p>{`if completed, add 4:00 and complete:`}</p>
    <p>{`13 Squat Cleans (175/115)`}</p>
    <p>{`15 T2B`}</p>
    <p>{`12 Bar Facing Burpees`}</p>
    <p>{`if completed, add 4:00 and complete:`}</p>
    <p>{`11 Squat Cleans (205/135)`}</p>
    <p>{`15 T2B`}</p>
    <p>{`12 Bar Facing Burpees`}</p>
    <p>{`if completed, add 4:00 and complete:`}</p>
    <p>{`9 Squat Cleans (245/155)`}</p>
    <p>{`15 T2B`}</p>
    <p>{`12 Bar Facing Burpees`}</p>
    <p>{`…4:00 to compete: 7 Squat Cleans \\@275, 5\\@295, 3\\@305, & 1\\@315`}</p>
    <p><strong parentName="p"><em parentName="strong">{`OR`}</em></strong></p>
    <p><em parentName="p">{`Option 2:`}</em></p>
    <p>{`21-1509 reps each of:`}</p>
    <p>{`Pistols/Leg`}</p>
    <p>{`Calorie Row`}</p>
    <p>{`Burpees Over Rower`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      